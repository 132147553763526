<template>
  <div class="root">
    <el-row type="flex" align="middle" justify="center" class="login">
      <el-col :xs="4" :sm="6" :md="8" :lg="9" :xl="11" class="login-box">
        <el-main>
          <img src="./assets/logo.png" alt="logo" class="img" />
          <span class="title">搜索系统</span>
          <span>
            <el-input
              class="big"
              clearable
              placeholder="请输入要查看答案的题目后，回车或者点击搜索按钮执行搜索。。"
              v-model="query.keyword"
              prefix-icon="el-icon-search"
              @keyup.enter.native="getAnswer()"
            />
            <el-button icon="el-icon-search" type="primary" size="small" class="search" @click="getAnswer">搜索</el-button>
            <!-- <el-button icon="el-icon-question" type="primary" size="small" class="search" @click="pianyiMessage">帮助</el-button> -->
          </span>
          <el-table :data="tableData" border style="width: 100%" height="50vh" highlight-current-row>
            <el-table-column label="序号" type="index" width="50px" align="center"></el-table-column>
            <el-table-column prop="description" label="题目">
              <template slot-scope="scope">
                <!-- <div v-html="scope.row.description"></div> -->
                <div>
                  <el-popover placement="left-end" width="20vw" trigger="hover">
                    题目
                    <div v-html="scope.row.description" style="color: #189ae9"></div>
                    答案：
                    <div v-html="scope.row.answer" style="color: #db0e89"></div>
                    <div v-show="scope.row.option0">
                      选项一
                      <div v-html="scope.row.option0" style="color: #0edb82"></div>
                      选项二
                      <div v-html="scope.row.option1" style="color: #0e8ddb"></div>
                      选项三
                      <div v-html="scope.row.option2" style="color: #dbd50e"></div>
                      选项四
                      <div v-html="scope.row.option3" style="color: #c26d2f"></div>
                    </div>
                    <div slot="reference" v-html="scope.row.description"></div>
                  </el-popover>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="answer" label="答案">
              <template slot-scope="scope">
                <div v-html="scope.row.answer"></div>
              </template>
            </el-table-column>
          </el-table>
        </el-main>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      query: {
        keyword: '',
        pageSize: 5,
        page: 1,
      },
      tableData: [],
    };
  },
  mounted() {
    this.getAnswer();
    this.pianyiMessage();
  },
  methods: {
    getAnswer() {
      axios
        .get('https://tiku.putianhui.cn/query', {
          params: this.query,
        })
        .then(Response => {
          this.tableData = Response.data.data;
          // this.tableData = Response.data.answer;
          this.query.query = '';
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    pianyiMessage() {
      this.$notify({
        title: '提示信息',
        message: '如果搜索关键词出现题目和自己题目不一致，请复制更完整的题目关键词进行搜索。',
        offset: 50,
        type: 'warning',
      });
    },
  },
};
</script>

<style lang="scss">
body {
  background: linear-gradient(
    90deg,
    rgba(247, 149, 51, 0.1),
    rgba(243, 112, 85, 0.1) 15%,
    rgba(239, 78, 123, 0.1) 30%,
    rgba(161, 102, 171, 0.1) 44%,
    rgba(80, 115, 184, 0.1) 58%,
    rgba(16, 152, 173, 0.1) 72%,
    rgba(7, 179, 155, 0.1) 86%,
    rgba(109, 186, 130, 0.1)
  );
}

.login {
  height: 98vh;
  width: 99vw;

  .login-box {
    // background: #ADA996;
    // background: -webkit-linear-gradient(to right, #EAEAEA, #DBDBDB, #F2F2F2, #ADA996);
    // background: linear-gradient(to right, #EAEAEA, #DBDBDB, #F2F2F2, #ADA996);
    background: rgba($color: #c0bdbd, $alpha: 0.5);

    height: 90vh;
    width: 55vw;
    border-radius: 5px;
    text-align: center;
    // padding-top: 10px;
    // transform: translateY(-10%);

    // .form {
    //   margin-top: 30px;
    // }

    img {
      width: 40px;
      vertical-align: middle;
      margin-bottom: 10px;
      margin-right: 12px;
    }

    .title {
      font-size: 25px;
      margin-bottom: 40px;
    }

    .big {
      width: 80%;
      height: 45px;
      margin-top: 8px;
    }

    .search {
      width: 80px;
      height: 40px;
      margin-top: 30px;
      margin-left: 5px;
      margin-bottom: 45px;
    }
  }
}
</style>
